import React, { useState } from "react";
import styled from "styled-components";
import phoneIcon from "../assets/images/nav/phone.svg";
import logo from "../assets/images/nav/logo.svg";
import facebookIcon from "../assets/images/nav/facebook.svg";
import twitterIcon from "../assets/images/nav/twitter.svg";
import youtubeIcon from "../assets/images/nav/youtube.svg";
import burgerIcon from "../assets/images/nav/burger.svg";
import { Link } from "react-router-dom";
import { Link as HashLink } from "react-scroll";

const Nav = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  return (
    <Header>
      <div className="top">
        <div className="left">
          <img src={phoneIcon} alt="call icon" />
          <Link to="tel:880-2223329090">
            <p>880-2223329090</p>
          </Link>
        </div>
        <div className="middle">
          <Link to="/" onClick={() => setMobileMenu(false)}>
            <img src={logo} alt="logo" />
          </Link>
          <img
            id="burger"
            onClick={() => setMobileMenu(!mobileMenu)}
            src={burgerIcon}
            alt="burger menu icon"
          />
        </div>
        <div className="right">
          <img src={facebookIcon} alt="facebook" />
          <img src={twitterIcon} alt="twitter" />
          <img src={youtubeIcon} alt="youtube" />
        </div>
      </div>
      <div className="bottom">
        <Link to="/">HOME</Link>
        <Link to="/portfolio">PORTFOLIO</Link>
        <Link to="/">SERVICES</Link>
        <Link to="/products">PRODUCTS</Link>
        <Link to="/clients" spy={true} smooth={true} duration={500}>
          CLIENTS
        </Link>
        <Link to="/story" onClick={() => setMobileMenu(false)}>
          OUR STORY
        </Link>
      </div>
      <div className={mobileMenu ? "burger-menu show" : "hide"}>
        <div className="container">
          <Link to="/" onClick={() => setMobileMenu(false)}>
            HOME
          </Link>
          <Link to="/portfolio" onClick={() => setMobileMenu(false)}>
            PORTFOLIO
          </Link>
          <Link to="/" onClick={() => setMobileMenu(false)}>
            SERVICES
          </Link>
          <Link to="/products" onClick={() => setMobileMenu(false)}>
            PRODUCTS
          </Link>
          <Link to="/clients" onClick={() => setMobileMenu(false)}>
            CLIENTS
          </Link>
          <Link to="/story" onClick={() => setMobileMenu(false)}>
            OUR STORY
          </Link>
          <HashLink
            to="contact-section"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => setMobileMenu(false)}
          >
            CONTACT
          </HashLink>
        </div>
      </div>
    </Header>
  );
};

export default Nav;

const Header = styled.nav`
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  .top {
    background-color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
    position: relative;
  }
  .bottom {
    background-color: #ffd31a;
    color: #000000;
    display: flex;
    justify-content: center;
    font-weight: 500;
    column-gap: 2rem;
    padding: 1rem 1.5rem;
    a {
      text-decoration: none;
      color: #000000;
      cursor: pointer;
      &:hover {
        color: rgb(217, 25, 11);
      }
    }
  }
  .left {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
    a {
      text-decoration: none;
      color: #ffffff;
    }
  }
  .right {
    display: flex;
    column-gap: 1.2rem;
    align-items: center;
  }

  #burger {
    display: none;
    z-index: 100;
  }

  .burger-menu {
    z-index: 10;
    /* display: none; */
    background-color: #000000;
    /* border: 1px solid red; */
    min-height: 90vh;
    position: absolute;
    top: 12vh;
    width: 100%;
    .container {
      display: flex;
      flex-direction: column;
      padding: 2rem 5rem;
      a {
        color: #ffffff;
        text-decoration: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.26);
        padding: 1rem;
        &:last-child {
          border: none;
        }
      }
    }
  }

  .show {
    display: block;
  }
  .hide {
    display: none;
  }

  @media only screen and (max-width: 1400px) {
    .middle {
      img {
        width: 180px;
      }
    }
    .bottom {
      padding: 0.5rem 1.5rem;
    }
  }

  @media only screen and (max-width: 600px) {
    .left,
    .right {
      display: none;
    }
    .middle {
      margin: auto;
      img {
        width: 180px;
      }
    }
    /* .burger-menu {
      display: block;
    } */
    #burger {
      display: block;
      width: 30px;
      position: absolute;
      right: 1.5rem;
      top: 32%;
    }
    .bottom {
      display: none;
    }
  }
`;
