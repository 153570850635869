import React from "react";
import styled from "styled-components";
import location from "../assets/images/footer/location.png";
import mail from "../assets/images/footer/mail.png";
import call from "../assets/images/footer/phone.png";
import facebookIcon from "../assets/images/nav/facebook.svg";
import twitterIcon from "../assets/images/nav/twitter.svg";
import youtubeIcon from "../assets/images/nav/youtube.svg";
import { Link } from "react-router-dom";
import { Link as HashLink } from "react-scroll";

const Footer = () => {
  return (
    <FooterStyle>
      <div className="container">
        <div className="footer">
          <div className="contact">
            <h1>Contact Info</h1>

            <div>
              <img src={location} alt="Address:" />
              <p>
                House: 41/1, Flat:4B(Main Road), Kaderabad Housing<br /> Mohammedpur,
                Dhaka-1207, Bangladesh
              </p>
            </div>
            <div>
              <img src={call} alt="location" />
              <p>880-2223329090, +880-1777777718, +880-1723333332</p>
            </div>
            <div>
              <img src={mail} alt="location" />
              <p>humayun_aircon@yahoo.com, panasonicvrf.aircon@gmail.com</p>
            </div>
          </div>
          <div className="navigation">
            <h1>Navigation</h1>
            <div>
              <Link to="/products">Products</Link>
              <Link to="/clients">Clients</Link>
              <Link to="/portfolio">Projects</Link>
              <Link to="/story">Our Story</Link>
              <HashLink to="contact-section">Contact Us</HashLink>
            </div>
          </div>
          <div className="social">
            <h1>Find Us</h1>
            <div>
              <img src={facebookIcon} alt="facebook" />
              <img src={twitterIcon} alt="twitter" />
              <img src={youtubeIcon} alt="youtube" />
            </div>
          </div>
        </div>
        <div className="copywright">
          <h2>&copy; 2023 AirCon Engineering Ltd.</h2>
          <h2>
            Design &amp; Development &nbsp;
            <span>
              {" "}
              <Link to="https://monokrome.dev/" target="_blank">
                Monokrome
              </Link>
            </span>
          </h2>
        </div>
      </div>
    </FooterStyle>
  );
};

export default Footer;

const FooterStyle = styled.div`
  .container {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5rem 0;
    row-gap: 5rem;
  }

  .copywright {
    display: flex;
    justify-content: space-between;
    span a {
      font-weight: 800;
      font-size: 20px;
      color: rgba(255, 255, 255, 0.322);
      letter-spacing: 1px;
      text-decoration: none;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
  }
  h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 2rem;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    padding: 0.2rem 0;
  }
  .navigation {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    a {
      color: #ffffff;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: rgb(217, 25, 11);
      }
    }
    div {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
  }

  .contact {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    div {
      display: flex;
      align-items: flex-start;
      column-gap: 0.5rem;
      p {
        line-height: 20px;
      }
      img {
        min-width: 25px;
        max-width: 25px;
      }
    }
  }

  .social {
    div {
      display: flex;
      column-gap: 1rem;
    }
  }

  h2 {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    padding-top: 2rem;
  }

  @media only screen and (max-width: 1400px) {
    .container {
      max-width: 1100px;

      padding: 3rem 0 5rem 0;
    }
  }

  @media only screen and (max-width: 600px) {
    .container,
    .footer {
      flex-direction: column;
      row-gap: 3rem;
      padding: 1rem 1rem;
    }
    .copywright {
      flex-direction: column;
      padding-bottom: 5rem;
    }
  }
`;
